@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'Calibre';
		src: url('./assets/fonts/calibre-regular.woff2');
	}
	@font-face {
		font-family: 'CalibreMedium';
		src: url('./assets/fonts/calibre-medium.woff2');
	}

	html {
		@apply font-calibre text-[62.5%] scroll-smooth;
	}

	body {
		@apply text-[16px] text-black leading-[1] font-normal overflow-x-hidden;
	}

	input,
	textarea {
		@apply outline-none;
	}
}
